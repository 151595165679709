<template>
  <div
      v-if="bannerText"
      class="fixed inset-x-0 bottom-0 bg-gray-900 text-white p-4 flex justify-between items-center"
      style="z-index: 100"
  >
    <p class="text-sm">
      {{ bannerText }}
      <a
          :href="cookiePolicyLink"
          class="underline text-blue-300"
      >Learn more</a>
    </p>
    <button
        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        @click="closeBanner"
    >
      Close
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CookieBanner',
  props: ['widgetId'],
  data() {
    return {
      bannerText: '',
      cookiePolicyLink: '',
    };
  },
  mounted() {
    this.fetchBannerData();
  },
  methods: {
    async fetchBannerData() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;
      this.bannerText = apiResponse.bannerText;
      this.cookiePolicyLink = apiResponse.cookiePolicyLink;

    },
    closeBanner() {
      // Logic to close the banner, e.g., setting a flag to hide it
      this.bannerText = '';
    }
  }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
