<template>
  <details class="group">
    <summary class="flex justify-between items-center p-4 cursor-pointer bg-gray-100 rounded-md">
      <h4 class="font-medium text-gray-800">
        {{ question }}
      </h4>
      <svg
          class="transform group-open:rotate-180 transition duration-300"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
      >
        <path
            d="M6 9l6 6 6-6"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>
    </summary>
    <p
        class="mt-2 p-4 bg-gray-50 rounded-md border border-gray-100"
        v-html="answer"
    />
  </details>
</template>

<script>
export default {
  props: {
    question: String,
    answer: String,
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>
