<template>
  <div class="fixed bottom-4 right-4 flex flex-col items-end">
    <div
        v-if="showChat"
        class="w-80 h-96 bg-white shadow-lg rounded-lg flex flex-col"
    >
      <div class="flex items-center p-4 bg-black rounded-t-lg">
        <img
            :src="profilePicture"
            alt="Customer Service"
            class="rounded-full"
            style="width: 2.5rem"
        >
        <h1 class="ml-4 font-semibold text-white">
          {{ serviceName }}
        </h1>
      </div>
      <div class="flex-1 p-4 overflow-auto">
        <div class="p-2 rounded-lg bg-blue-100 max-w-max mb-2">
          <p><strong>{{ serviceName }}</strong></p>
          <p>{{ initialMessage }}</p>
        </div>
      </div>
      <div class="px-4 pb-4 pt-2 border-t border-gray-200 flex">
        <input
            v-model="userMessage"
            style="max-width: 245px"
            type="text"
            placeholder="Type a message..."
            class="border-gray-300 rounded-lg p-2 flex-1 mr-2"
        >

        <button
            class="bg-white text-wp hover:bg-gray-100 text-white font-bold p-2 rounded-full flex-none"
            @click="sendMessage"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2 -2 24 24"
              width="20"
              fill="currentColor"
          >
            <path
                d="M16.907 4.44l-7.655 7.655c.227.325.42.676.575 1.048l1.91 4.599 5.17-13.303zm-9.002 6.308l7.656-7.656-13.303 5.17 4.599 1.911a5.51 5.51 0 0 1 1.048.575zm11.618-7.862l-6.027 15.506c-.38.98-1.477 1.483-2.449 1.124a1.831 1.831 0 0 1-1.057-1.017L8.08 13.9a3.662 3.662 0 0 0-1.98-1.98l-4.6-1.91C.546 9.613.11 8.51.528 7.544a1.95 1.95 0 0 1 1.08-1.04L17.114.475a1.852 1.852 0 0 1 2.41 2.41z"
            />
          </svg>
        </button>
      </div>
    </div>
    <button
        style="margin-top: 10px;"
        class="whatsappButton bg-white hover:bg-gray-100 text-white font-bold py-2 px-4 rounded-full"
        @click="toggleChat"
    >
      <svg
          class="inline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 -2 24 24"
          width="28"
          fill="currentColor"
      >
        <path
            d="M9.516.012C4.206.262.017 4.652.033 9.929a9.798 9.798 0 0 0 1.085 4.465L.06 19.495a.387.387 0 0 0 .47.453l5.034-1.184a9.981 9.981 0 0 0 4.284 1.032c5.427.083 9.951-4.195 10.12-9.58C20.15 4.441 15.351-.265 9.516.011zm6.007 15.367a7.784 7.784 0 0 1-5.52 2.27 7.77 7.77 0 0 1-3.474-.808l-.701-.347-3.087.726.65-3.131-.346-.672A7.62 7.62 0 0 1 2.197 9.9c0-2.07.812-4.017 2.286-5.48a7.85 7.85 0 0 1 5.52-2.271c2.086 0 4.046.806 5.52 2.27a7.672 7.672 0 0 1 2.287 5.48c0 2.052-.825 4.03-2.287 5.481z"
        />
        <path
            d="M14.842 12.045l-1.931-.55a.723.723 0 0 0-.713.186l-.472.478a.707.707 0 0 1-.765.16c-.913-.367-2.835-2.063-3.326-2.912a.694.694 0 0 1 .056-.774l.412-.53a.71.71 0 0 0 .089-.726L7.38 5.553a.723.723 0 0 0-1.125-.256c-.539.453-1.179 1.14-1.256 1.903-.137 1.343.443 3.036 2.637 5.07 2.535 2.349 4.566 2.66 5.887 2.341.75-.18 1.35-.903 1.727-1.494a.713.713 0 0 0-.408-1.072z"
        />
      </svg>
      Whatsapp
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'WhatsappChatWidget',
  props: ['widgetId'],
  data() {
    return {
      showChat: false,
      userMessage: '',
      serviceName: 'Loading...',
      initialMessage: 'Please wait...',
      profilePicture: process.env.VUE_APP_APP_URL + '/imgs/blank-profile.png',
      whatsappNumber: ''
    };
  },
  mounted() {
    this.fetchServiceInfo();
  },
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
    },
    sendMessage() {
      let baseRedirect = 'https://api.whatsapp.com/send/?type=phone_number&app_absent=0'
      baseRedirect += '&phone=44' + this.whatsappNumber
      baseRedirect += '&text=' + encodeURI(this.userMessage)
      window.open(baseRedirect, '_blank')
      this.userMessage = '';  // Clear the input after sending
    },
    async fetchServiceInfo() {
      // Example API response structure
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.serviceName = apiResponse.display_name
      this.initialMessage = apiResponse.default_message;
      this.whatsappNumber = apiResponse.whatsapp_number;

      if (apiResponse.profilePicture) {
        this.profilePicture = apiResponse.profilePicture
      }
    }
  }
}
</script>

<style scoped>
/* Your styles here */
.whatsappButton {
  color: rgb(79, 206, 93);
  border-color: rgb(79, 206, 93);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px 0px;
}

.text-wp {
  color: rgb(79, 206, 93);
}
</style>
