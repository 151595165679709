<template>
  <div class="max-w-sm mx-auto bg-white rounded-lg p-4">
    <div class="mb-4">
      <p
          :style="{ color: statusColour }"
          class="text-lg font-semibold text-center"
      >
        {{ statusMessage }}
      </p>
    </div>

    <div class="space-y-2">
      <div
          v-for="(hours, day) in openingHours"
          :key="day"
          :class="{'bg-gray-200': day === currentDay}"
          class="px-2 flex justify-between"
      >
        <span class="font-medium">{{ day }}</span>
        <span>{{ formatHours(hours) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OpeningHoursWidget",
  props: ['widgetId'],
  data() {
    return {
      openingHours: {},
      currentDay: '',
      statusMessage: '',
      statusClass: '',
      statusColour: '',
      openColour: '#4caf50',
      closingSoonColour: '#ffeb3b',
      closedColour: '#f44336',
    };
  },
  mounted() {
    this.fetchOpeningStatus();
  },
  methods: {
    async fetchOpeningStatus() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.openingHours = apiResponse.openingHours;
      this.currentDay = new Date().toLocaleDateString('en-US', {weekday: 'long'});

      if (apiResponse.open_text_colour) this.openColour = apiResponse.open_text_colour;
      if (apiResponse.closing_soon_text_colour) this.closingSoonColour = apiResponse.closing_soon_text_colour;
      if (apiResponse.closed_text_colour) this.closedColour = apiResponse.closed_text_colour;


      this.updateStatus();
    },
    updateStatus() {
      const today = this.openingHours[this.currentDay];
      if (!today.open || !today.close) {
        this.statusMessage = "Closed";
        this.statusClass = "text-red-500";
        this.statusColour = this.closedColour
        return;
      }

      const now = new Date();
      const openTime = this.parseTime(today.open);
      const closeTime = this.parseTime(today.close);
      const closingSoonThreshold = 30 * 60 * 1000; // 30 minutes in milliseconds

      if (now >= openTime && now <= closeTime) {
        if ((closeTime - now) <= closingSoonThreshold) {
          this.statusMessage = "Closing Soon";
          this.statusClass = "text-yellow-500";
          this.statusColour = this.closingSoonColour
        } else {
          this.statusMessage = "Open Now";
          this.statusClass = "text-green-500";
          this.statusColour = this.openColour
        }
      } else {
        this.statusMessage = "Closed";
        this.statusClass = "text-red-500";
        this.statusColour = this.closedColour
      }
    },
    parseTime(time) {
      const [hours, minutes] = time.split(':');
      const now = new Date();
      const parsedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
      return parsedTime;
    },
    formatHours(hours) {
      if (!hours.open || !hours.close) return "Closed";
      const options = {hour: 'numeric', minute: 'numeric', hour12: true};
      const openTime = this.parseTime(hours.open);
      const closeTime = this.parseTime(hours.close);
      return openTime.toLocaleTimeString('en-US', options) + ' - ' + closeTime.toLocaleTimeString('en-US', options);
    }
  }
};
</script>

<style scoped>
.bg-gray-200 {
  background-color: #e2e8f0;
}
</style>
