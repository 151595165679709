<template>
  <div class="image-container relative">
    <img
        id="loadedImage"
        ref="image"
        :src="hotspotConfig.image"
        alt="Hotspot Image"
        class="hotspot-image"
        @load="onImageLoad"
    >
    <div
        v-for="(point, index) in hotspotConfig.data"
        :key="index"
        class="hotspot-point absolute"
        :style="getHotspotStyle(point)"
        @mouseover="showPopup(point)"
        @mouseleave="hidePopup"
    >
      <div class="ring-container">
        <div class="ringring" />
        <div class="circle" />
      </div>
    </div>
    <div
        v-if="popupVisible"
        class="popup absolute"
        :style="{ left: popupLeft + 'px', top: popupTop + 'px' }"
    >
      <h3>{{ popupTitle }}</h3>
      <p>{{ popupDescription }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageHotspotWidget",
  props: ["widgetId"],
  data() {
    return {
      originalWidth: 548, // Set this to the original width of your image
      originalHeight: 230, // Set this to the original height of your image
      hotspotConfig: {
        image: "",
        data: [],
      },
      imageWidth: 0,
      imageHeight: 0,
      popupVisible: false,
      popupTitle: "",
      popupDescription: "",
      popupLeft: 0,
      popupTop: 0,
    };
  },
  mounted() {
    this.fetchServiceInfo();
    window.addEventListener("resize", this.updateImageDimensions);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateImageDimensions);
  },
  methods: {
    async fetchServiceInfo() {
      // Api Connection
      let rawApiResponse = await axios.get(
          process.env.VUE_APP_API_URL + "/api/widget/" + this.widgetId
      );
      let apiResponse = rawApiResponse.data;

      this.hotspotConfig.image = apiResponse.image;
      this.hotspotConfig.data = apiResponse.image_markers;
    },
    onImageLoad() {
      this.updateImageDimensions();
    },
    updateImageDimensions() {
      let image = document.getElementById('loadedImage');

      if (image) {
        this.imageWidth = image.width;
        this.imageHeight = image.height;
      }
    },
    getHotspotStyle(point) {
      if (!this.imageWidth || !this.imageHeight) return {};

      const relativeX = (point.x / this.originalWidth) * this.imageWidth;
      const relativeY = (point.y / this.originalHeight) * this.imageHeight;

      return {
        left: `${relativeX}px`,
        top: `${relativeY}px`,
        transform: "translate(-50%, -50%)", // Center the hotspot
      };
    },
    showPopup(point) {
      const image = this.$refs.image;
      if (image) {
        const rect = image.getBoundingClientRect();

        const relativeX = (point.x / this.originalWidth) * rect.width;
        const relativeY = (point.y / this.originalHeight) * rect.height;

        this.popupLeft = relativeX + 30; // Adjust position as needed
        this.popupTop = relativeY - 40; // Adjust position as needed

        this.popupTitle = point.title;
        this.popupDescription = point.description;
        this.popupVisible = true;
      }
    },
    hidePopup() {
      this.popupVisible = false;
    },
  },
};
</script>

<style>
.image-container {
  position: relative;
  display: inline-block;
}

.hotspot-image {
  width: 100%;
  height: auto;
}

.hotspot-point {
  position: absolute;
  width: 25px;
  height: 25px;
  //background-color: red;
  border-radius: 50%;
  cursor: pointer;
}

.popup {
  position: absolute !important;
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 10;
  max-width: 200px;
}

.popup h3 {
  margin: 0 0 5px 0;
  font-size: 1.1em;
}

.popup p {
  margin: 0;
  font-size: 0.9em;
}

.ring-container {
  position: relative;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 10px;
  top: 10px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}
</style>
