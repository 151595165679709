<template>
  <div class=" twind">
    <ReviewTabs
        :tabs="tabs"
        :active-tab="activeTab"
        :overall-rating="overallStarRating"
        @change-tab="changeTab"
    />
    <ReviewContent
        :active-tab="activeTab"
        :tabs="tabs"
        :all-reviews="allReviews"
        :overall-rating="overallStarRating"
    />
  </div>
</template>

<script>
import ReviewTabs from './components/ReviewTabs.vue'
import ReviewContent from './components/ReviewContent.vue'
import axios from "axios";

export default {
  name: 'ReviewsWidget',
  components: {
    ReviewTabs,
    ReviewContent
  },
  props: ['widgetId'],
  data() {
    return {
      activeTab: -1,
      tabs: [],
      overallStarRating: 0,
      allReviews: []
    };
  },
  mounted() {
    this.fetchTabData();
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    async fetchTabData() {
      console.log(this.widgetId)

      // Example API response structure
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;
      this.tabs = apiResponse.tabs;
      this.overallStarRating = apiResponse.overallStarRating;
      let randomisedReviews = this.shuffleArray(apiResponse.allReviews);
      this.allReviews = randomisedReviews;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }

      return array;
    }
  }
};
</script>

<style scoped>
.review-tab-active {
  background: #9e9fa0;
}
</style>
