<template>
  <div class="container mx-auto p-4">
    <div class="grid md:grid-cols-3 gap-4">
      <div class="md:col-span-2">
        <h2 class="text-2xl font-bold mb-4">
          Your Basket
        </h2>

        <div
            v-if="basketStore.items.length === 0"
            class="text-center p-4"
        >
          Your basket is empty.
        </div>

        <div
            v-else
            class="space-y-4"
        >
          <BasketItem
              v-for="item in basketStore.items"
              :key="item.id"
              :item="item"
              @remove="removeFromBasket(item.id)"
              @update-quantity="updateQuantity(item.id, $event)"
          />
        </div>
      </div>

      <BasketSummary />
    </div>
  </div>
</template>

<script>
import {useBasketStore} from '@/store/basket';
import BasketItem from "@/components/ecommerce/pages/basket/components/BasketItem.vue";
import BasketSummary from "@/components/ecommerce/pages/basket/components/BasketSummary.vue";

export default {
  name: "BasketPage",
  components: {
    BasketItem,
    BasketSummary,
  },
  setup() {
    const basketStore = useBasketStore();

    const removeFromBasket = (itemId) => {
      basketStore.removeItem(itemId);
    };

    const updateQuantity = (itemId, newQuantity) => {
      basketStore.updateItemQuantity(itemId, newQuantity);
    };

    return {basketStore, removeFromBasket, updateQuantity};
  },
};
</script>
