<template>
  <div class="p-4 shadow">
    <h2 class="text-2xl font-bold mb-4">
      Summary
    </h2>
    <div class="mb-4">
      <label
          for="coupon"
          class="block mb-2"
      >Coupon Code</label>
      <input
          id="coupon"
          v-model="couponCode"
          type="text"
          placeholder="Enter your code"
          class="border p-2 w-full"
          @input="applyCoupon"
      >
    </div>
    <div class="mb-4">
      <div class="flex justify-between">
        <span>Subtotal:</span>
        <span>{{ formatPrice(basketStore.totalPrice) }}</span>
      </div>
      <div
          v-if="basketStore.coupon"
          class="flex justify-between"
      >
        <span>Discount:</span>
        <span>-{{ formatPrice(basketStore.coupon.discountAmount) }}</span>
      </div>
    </div>
    <div class="border-t-2 pt-4 mb-4">
      <div class="flex justify-between font-bold">
        <span>Total:</span>
        <span>{{
            formatPrice(basketStore.totalPrice - (basketStore.coupon ? basketStore.coupon.discountAmount : 0))
          }}</span>
      </div>
    </div>
    <button class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Continue to Checkout
    </button>
  </div>
</template>

<script>
import {useBasketStore} from '@/store/basket';

export default {
  setup() {
    const basketStore = useBasketStore();
    const couponCode = '';

    const applyCoupon = () => {
      basketStore.applyCoupon(couponCode);
    };

    return {basketStore, couponCode, applyCoupon};
  },
  methods: {
    formatPrice(price) {
      // Assuming price is in cents
      return `£${(price / 100).toFixed(2)}`;
    }
  }
};
</script>
