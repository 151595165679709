<template>
  <div>
    <div
        class="max-w-4xl mx-auto p-8"
        style="padding-bottom: 0"
    >
      <div>
        <input
            v-model="searchQuery"
            type="search"
            placeholder="Search..."
            aria-label="Search through content"
            class="w-full p-4 text-gray-800 border rounded-md"
            @input="filterFAQs"
        >
      </div>
    </div>
    <category-template
        v-if="showCategories"
        :categories="filteredCategories"
    />
    <categoryless-template
        v-else
        :faqs="filteredFAQs"
    />
  </div>
</template>

<script>
import CategoryTemplate from './layouts/categoryTemplate.vue';
import CategorylessTemplate from './layouts/categorylessTemplate.vue';
import axios from "axios";


export default {
  name: "FAQWidget",
  components: {
    CategoryTemplate,
    CategorylessTemplate,
  },
  props: ['widgetId'],
  data() {
    return {
      showCategories: false,
      categories: [],
      faqs: [],
      searchQuery: '',
      filteredCategories: [],
      filteredFAQs: [],
    };
  },
  created() {
    this.fetchFAQs();
  },
  methods: {
    async fetchFAQs() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.showCategories = apiResponse.show_categories;
      this.categories = apiResponse.categories;
      this.filteredCategories = this.categories;
      this.faqs = this.categories.flatMap(category => category.faqs);
      this.filteredFAQs = this.faqs;
    },
    filterFAQs() {
      console.log(1)
      const query = this.searchQuery.toLowerCase();
      if (this.showCategories) {
        this.filteredCategories = this.categories.map(category => {
          return {
            ...category,
            faqs: category.faqs.filter(faq =>
                faq.question.toLowerCase().includes(query) || faq.answer.toLowerCase().includes(query)
            ),
          };
        }).filter(category => category.faqs.length > 0);
      } else {
        this.filteredFAQs = this.faqs.filter(faq =>
            faq.question.toLowerCase().includes(query) || faq.answer.toLowerCase().includes(query)
        );
      }
    },
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>