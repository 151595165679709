import { defineStore } from 'pinia';

export const useBasketStore = defineStore('basket', {
    state: () => ({
        items: [],  // Array to hold product objects: { id, name, price, quantity, ... }
        sessionId: null, // This is a session-id to be used to identify this basket
        coupon: null, // Object for coupon details: { code, discountAmount, ... }
        shippingMethod: null, // Shipping option chosen by user
        shippingAddress: null, // Address object for delivery
        billingAddress: null, // Address object for billing
    }),
    getters: {
        // Example getter to calculate the total price
        totalPrice() {
            let total = 0;
            for (const item of this.items) {
                total += item.price * item.quantity;
            }
            // Apply coupon discount if available
            if (this.coupon) {
                // ... discount calculation logic
            }
            return total;
        },
    },
    actions: {
        addItem() {
            // Logic to add a product to the basket
            // (handle quantity updates, check for duplicates, etc.)
        },
        removeItem() {
            // Logic to remove a product from the basket
        },
        updateItemQuantity() {
            // Logic to update the quantity of an item
        },
        applyCoupon() {
            // Logic to fetch and apply a coupon
        },
        setShippingMethod() {
            // ...
        },
        setShippingAddress() {
            // ...
        },
        setItems(items) {
            this.items = items;
        },
        setSessionId(sessionId) {
            this.sessionId = sessionId;
            localStorage.setItem('cartSessionId', sessionId);
        },
    },
});
