<template>
  <div
      class="hidden md:flex fixed right-0 top-[184.5px] z-50 flex-col sidebar_buttons"
      :style="{ backgroundColor: buttonColor, borderColor: lightenDarkenColor(buttonColor, -50), borderRadius: '10px 0 0 10px', maxWidth: '100px'}"
  >
    <div
        v-for="(link, index) in links"
        :key="index"
        class="cursor-pointer leading-6 text-decoration-line[none] flex justify-center items-center px-2"
        :class="buttonClasses(index)"
        :style="{
          backgroundColor: index === 0 ? buttonColor : '',
          color: textColor,
          height: buttonHeight(index),
          width: buttonWidth(),
          borderColor: index !== 0 ? lightenDarkenColor(buttonColor, -20) : 'transparent',
          ':hover': { backgroundColor: lightenDarkenColor(buttonColor, -10) }
        }"
        @click="handleClick(link.action)"
    >
      <a
          :href="link.type === 'url' ? link.action : ''"
          class="no-underline flex items-center space-x-2"
      >
        <i
            v-if="link.icon"
            :class="link.icon"
        />
        <p class="uppercase text-xs md:text-sm lg:text-lg">{{ link.text }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'SidebarButtons',
  props: ['widgetId'],
  data() {
    return {
      links: [], // This will hold the links fetched from the API
      buttonColor: '', // Hex color for button background
      textColor: '' // Hex color for text
    };
  },
  mounted() {
    this.fetchLinks();
  },
  methods: {
    async fetchLinks() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let response = rawApiResponse.data;
      // Mocking an API call
      this.buttonColor = response.button_colour; // Example: a green color
      this.textColor = response.text_colour; // Example: white for text
      this.links = response.links;
    },
    handleClick(action) {
      if (typeof action === 'string' && action.startsWith('javascript:')) {
        eval(action.substring(11)); // Executes the JavaScript code
      }
    },
    lightenDarkenColor(col, amt) {
      let usePound = false;
      if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
      }
      let num = parseInt(col, 16);
      let r = (num >> 16) + amt;
      let b = ((num >> 8) & 0x00FF) + amt;
      let g = (num & 0x0000FF) + amt;
      return (usePound ? "#" : "") + ((g | (b << 8) | (r << 16)).toString(16).padStart(6, '0'));
    },
    buttonClasses(index) {
      return [
        'md:w-22 md:h-[190px]', // Medium screens
        'lg:w-28 lg:h-[198px]', // Large screens
        {
          'rounded-tl-[25px] rounded-tr-[25px]': index === 0,
          'rounded-bl-[25px] rounded-br-[25px]': index === this.links.length - 1,
          'border-t': index !== 0
        }
      ];
    },
    buttonHeight(index) {
      // Return a dynamic height based on the index
      if (index === 0) return '148px'; // First button
      if (index === this.links.length - 1) return '148px'; // Last button
      return '120px'; // Middle buttons
    },
    buttonWidth() {
      return '100%'; // Full width of the parent container
    }
  }
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
</style>
