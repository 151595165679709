<template>
  <div id="tabs">
    <nav
        id="reviewTabs"
        class="flex space-x-1"
    >
      <a
          href="#"
          class="px-3 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
          aria-current="page"
          :class="{ 'review-tab-active': activeTab === -1 }"
          @click.prevent="changeTab(-1)"
      >
        All - {{ overallRating }}
      </a>
      <a
          v-for="(tab, index) in tabs"
          :key="index"
          href="#"
          class="px-3 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
          :class="{ 'review-tab-active': activeTab === index }"
          @click.prevent="changeTab(index)"
      >
        {{ tab.name }} - {{ tab.starRating }}
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['tabs', 'activeTab', 'overallRating'],
  emits: ['change-tab'],
  methods: {
    changeTab(index) {
      this.$emit('change-tab', index);
    }
  }
};
</script>

<style scoped>
.review-tab-active {
  background: #9e9fa0;
}
</style>
