<template>
  <div class="tailwind">
    <div
        class="fixed bottom-10 right-10 flex items-center justify-center w-16 h-16 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 transition-colors cursor-pointer"
        @click="gotoCart"
    >
      <svg
          fill="currentColor"
          class="h-8 w-8 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
      >
        <path
            d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
        />
      </svg>
      <span
          class="cart-items-count absolute top-0 right-0 flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 rounded-full"
      >
        {{ basketItemCount }}
      </span>
    </div>
  </div>
</template>


<script>
import {useBasketStore} from '@/store/basket';

export default {
  data() {
    return {
      showBasket: false,
    };
  },
  computed: {
    basketItemCount() {
      const basketStore = useBasketStore();
      return basketStore.items.length; // Assuming items is an array in your store
    },
  },
  methods: {
    toggleBasketVisibility() {
      this.showBasket = !this.showBasket;
    },
    gotoCart() {
      this.$emit('change-page', 'basket')
    }
  }
};
</script>

<style>
/* Basic styling for the basket overlay */
.basket-overlay {
  /* ... */
}

.basket-content {
  /* ... */
}
</style>
