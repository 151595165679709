import { useBasketStore } from "@/store/basket";
import axios from 'axios';
import LocalStorageService from "@/services/LocalStorageService";

export default class CartService {
    constructor(config) {
        this.config = config; // Base API URL
        this.baseUrl = 'https://' + config.site + '.' + process.env.VUE_APP_ECOM_BASE_URL

        // Create Axios instance with base URL
        this.axios = axios.create({
            baseURL: this.baseUrl,
        });
    }

    async initCart() {
        let existingSessionId = LocalStorageService.getItem('cart-session');
        const basketStore = useBasketStore();
        let queryParams = '';

        // Check for existing session ID in local storage
        if (existingSessionId) {
            queryParams = '?session_id=' + existingSessionId
        }

        // Initialize a new cart
        const response = await this.axios.post(`/api/cart/init` + queryParams);
        const cartData = response.data.cart;

        // Store updates in the store.
        basketStore.setItems(cartData.items);
        basketStore.setSessionId(cartData.session_id); // Store new session ID
        LocalStorageService.setItem('cart-session', cartData.session_id, 10080)
    }

    // async addItem(item) {
    //     // ... API call to add item
    // }
    //
    // async removeItem(itemId) {
    //     // ... API call to remove item
    // }

    // ... other cart-related methods
}
