<template>
  <div v-if="!loading">
    <Vue3EasyDataTable
        :headers="columns"
        :items="data"
    />
  </div>
</template>

<script>
import axios from "axios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

export default {
  name: 'DatatableWidget',
  components: {
    Vue3EasyDataTable
  },
  props: ['widgetId'],
  data() {
    return {
      columns: [],
      data: [],
      loading: true,
    };
  },
  mounted() {
    this.fetchLinks();
  },

  methods: {
    async fetchLinks() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let response = rawApiResponse.data;

      this.data = response.data
      this.columns = response.headers
      this.loading = false;

    }
  }
}
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
</style>
