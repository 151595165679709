<template>
  <div v-if="!loading">
    <component
        :is="layout"
        :pricing-table-details="pricingTableDetails"
    />
  </div>
</template>

<script>
import HorizontalCards from "@/components/widgets/pricing-table-widget/layouts/HorizontalCards.vue";
import axios from "axios";
export default {
  name: "PricingTableWidget",
  components: {
    HorizontalCards
  },
  props: ['widgetId'],
  data() {
    return {
      loading: true,
      pricingTableDetails: null,
      layout: null,
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.layout = apiResponse.layout
      this.pricingTableDetails = apiResponse
      this.loading = false
    },
  },
};
</script>