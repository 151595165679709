<template>
  <div
      id="pollWidget"
      class="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden"
  >
    <PollForm
        v-if="!hasVoted"
        :title="title"
        :options="options"
        @vote-submitted="handleVote"
    />
    <PollResults
        v-else
        :results="results"
    />
  </div>
</template>

<script>
import PollForm from '../components/PollForm.vue';
import PollResults from '../components/PollResults.vue';

export default {
  components: {
    PollForm,
    PollResults
  },
  props: {
    options: Array,
    title: String,
    hasVoted: Boolean,
    results: Object
  },
  methods: {
    handleVote(option) {
      this.$emit('vote-submitted', option);
    }
  }
};
</script>
