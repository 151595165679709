<!-- widget/components/QuizResult.vue -->
<template>
  <div class="max-w-md mx-auto shadow-lg rounded-lg p-6 space-y-4 text-center">
    <h1 class="text-2xl font-bold">
      Quiz Results
    </h1>
<!--    <img-->
<!--        src="https://picsum.photos/200/200"-->
<!--        alt="Results Image"-->
<!--        class="w-1/2 mx-auto rounded-full"-->
<!--    >-->
    <p class="font-semibold">
      {{ quizCompletedText }}
    </p>
    <div class="flex justify-center">
      <button
          class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          @click="retryQuiz"
      >
        Take Quiz Again
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quizCompletedText: String
  },
  methods: {
    retryQuiz() {
      this.$emit('retryQuiz');
    }
  }
};
</script>
