<template>
  <div v-if="!commerceRender">
    <div
        v-for="widget in location.widgets"
        :key="widget.id"
    >
      <component
          :is="'widget-' + widget.id"
          :widget-id="widget.uuid"
      />
    </div>
  </div>
  <div
      v-else
      class="tailwind"
  >
    <h1>This is a commerce render</h1>
    <ecom-master
        :render="ecommerce"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import EcomMaster from "@/components/ecommerce/EcomMaster.vue";

export default {
  components: {
    EcomMaster
  },
  setup() {
    let location = inject('location');
    let ecommerce = inject('render');
    let commerceRender = false;

    if (!location) {
      console.error('No Location Data')
      location = {};
    }

    if (ecommerce) {
      commerceRender = true;
    } else {
      ecommerce = {}
    }

    console.log(ecommerce);


    return { location, ecommerce, commerceRender };
  }
}
</script>

<style scoped>
/* Your styles here */
</style>
