<template>
  <section>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div
          v-if="pricingTableDetails.billingToggleEnabled"
          class="flex justify-center mb-4"
      >
        <label class="flex items-center cursor-pointer">
          <span class="mr-2 text-lg">Monthly</span>
          <input
              v-model="isYearly"
              type="checkbox"
              class="toggle-checkbox hidden"
          >
          <div class="toggle-switch bg-gray-200 relative inline-block h-6 w-12 rounded-full">
            <span
                class="toggle-switch-slider bg-white absolute left-1 top-1 h-4 w-4 rounded-full transition-transform"
                :class="{'transform translate-x-6': isYearly}"
            />
          </div>
          <span class="ml-2 text-lg">Yearly</span>
        </label>
      </div>
      <div class="space-y-8 grid grid-cols-1 lg:flex sm:gap-6 xl:gap-10 lg:space-y-0">
        <div
            v-for="(plan, index) in pricingTableDetails.plans"
            :key="index"
            :class="['flex w-1/2 lg:w-1/3 flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8', {'recommended-plan': plan.recommended}]"
            :style="plan.recommended ? {'border-color': plan.recommendedColor, 'box-shadow': '0 0 15px ' + plan.recommendedColor } : {}"
        >
          <div
              v-if="plan.recommended"
              class="recommended-banner"
              :style="{ 'background-color': plan.recommendedColor }"
          >
            {{ plan.recommendedText }}
          </div>
          <h3 class="mb-4 text-2xl font-semibold">
            {{ plan.name }}
          </h3>
          <p class="font-light text-gray-500 sm:text-lg">
            {{ plan.description }}
          </p>
          <div class="flex justify-center items-baseline my-8">
            <span class="mr-2 text-5xl font-extrabold">{{ isYearly ? plan.yearlyPrice : plan.monthlyPrice }}</span>
            <span class="text-gray-500">/{{ isYearly ? 'year' : 'month' }}</span>
          </div>
          <ul
              role="list"
              class="mb-8 space-y-4 text-left"
          >
            <li
                v-for="(feature, featureIndex) in plan.features"
                :key="featureIndex"
                class="flex items-center space-x-3"
            >
              <span
                  class="h-7 w-7 text-gray-500"
                  v-html="pricingTableDetails.icon"
              />
              <span>{{ feature }}</span>
            </li>
          </ul>
          <a
              :href="plan.buttonLink"
              class="text-white focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              :style="{ 'background-color': plan.buttonColour }"
          >{{ plan.buttonText }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pricingTableDetails: Object,
  },
  data() {
    return {
      isYearly: false,
    };
  },
};
</script>

<style scoped>
.toggle-switch {
  transition: background-color 0.2s;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: #4CAF50;
}

.toggle-switch-slider {
  transition: transform 0.2s;
}

.recommended-banner {
  position: absolute;
  color: white;
  padding: 0.25em 0.5em;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  border-radius: 0.25em;
}

.recommended-plan {
  position: relative;
}
</style>