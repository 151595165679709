<template>
  <ImgComparisonSlider>
    <!-- eslint-disable -->
    <img
        slot="first"
        style="width: 100%"
        :src="leftImage"
    />
    <img
        slot="second"
        style="width: 100%"
        :src="rightImage"
    />
    <!-- eslint-enable -->
  </ImgComparisonSlider>
</template>
<script>
import {ImgComparisonSlider} from "@img-comparison-slider/vue";
import axios from "axios";

export default {
  name: 'ImageComparisonSlider',
  components: {
    ImgComparisonSlider
  },
  props: ['widgetId'],
  data() {
    return {
      leftImage: null,
      rightImage: null
    };
  },
  mounted() {
    this.fetchTabData();
  },
  methods: {

    async fetchTabData() {
      // Example API response structure
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;
      console.log(apiResponse);

      this.leftImage = 'https://projectinsanity.cloud' + apiResponse.left_image;
      this.rightImage = 'https://projectinsanity.cloud' + apiResponse.right_image;
    },
  }
};
</script>