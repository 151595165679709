<template>
  <div class="max-w-4xl mx-auto p-8">
    <div class="space-y-10">
      <Category
        v-for="(category, index) in categories"
        :key="index"
        :icon="category.icon"
        :title="category.category"
        :subtitle="category.subtitle"
        :faqs="category.faqs"
      />
    </div>
  </div>
</template>

<script>
import Category from '../components/Category.vue';

export default {
  components: {
    Category,
  },
  props: {
    categories: Array,
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>
