<template>
  <div class="flex justify-center items-center mb-2">
    <span class="text-3xl font-medium mr-2">{{ rating }}</span>
    <span class="text-yellow-400 text-3xl flex">
      <FontAwesomeIcon
v-for="(icon, index) in displayStarRating(rating)"
:key="index"
:icon="icon"
/>
    </span>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faStar as fasStar, faStarHalfAlt as fasStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as fasStarEmpty } from "@fortawesome/free-regular-svg-icons";

export default {
  components: {
    FontAwesomeIcon
  },
  props: ['rating'],
  methods: {
    displayStarRating(rating) {
      const fullStars = Math.floor(rating);
      const hasHalfStar = rating % 1 !== 0;
      const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
      let stars = [];
      for (let i = 0; i < fullStars; i++) {
        stars.push(fasStar);
      }
      if (hasHalfStar) {
        stars.push(fasStarHalfAlt);
      }
      for (let i = 0; i < emptyStars; i++) {
        stars.push(fasStarEmpty);
      }
      return stars;
    }
  }
};
</script>
