<template>
  <component
      :is="currentLayout"
      :title="title"
      :options="options"
      :has-voted="hasVoted"
      :results="results"
      @vote-submitted="submitVote"
  />
</template>

<script>
import TextPoll from './layouts/TextPoll.vue';
import axios from "axios";

export default {
  name: "PollWidget",
  components: {
    TextPoll
  },
  props: ['widgetId'],
  data() {
    return {
      currentLayout: null,
      title: '',
      options: [],
      hasVoted: false,
      results: {}
    };
  },
  created() {
    this.fetchPollData()
  },
  methods: {
    async fetchPollData() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;
      this.currentLayout = apiResponse.layout;
      this.title = apiResponse.title;
      this.options = apiResponse.options;

      // Check if the user has already voted on this poll
      this.hasVoted = localStorage.getItem(`poll_${this.widgetId}_voted`) === 'true';

      // If voted already load the results
      if (this.hasVoted) this.submitVote('results')
    },
    async submitVote(option) {
      if (this.hasVoted) {
        // If the user has already voted, change the option to 'results'
        option = 'results';
      }

      // Send the vote
      let rawApiResponse = await axios.post(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId, {
        'optionId': option
      });

      this.results = rawApiResponse.data;

      // Store the flag indicating the user has voted
      localStorage.setItem(`poll_${this.widgetId}_voted`, 'true');
      this.hasVoted = true;

    }
  }
};
</script>