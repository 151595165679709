<template>
  <div class="p-4">
    <h3 class="font-semibold text-lg">
      Results
    </h3>
    <div
        id="resultsContainer"
        class="mt-4 space-y-2"
    >
      <div
          v-for="(percentage, label) in results"
          :key="label"
          class="text-sm font-medium"
      >
        {{ label }}
        <div class="w-full bg-gray-200 rounded-full h-3.5 dark:bg-gray-700">
          <div
              class="bg-blue-600 h-3.5 rounded-full"
              :style="{ width: percentage + '%' }"
          />
        </div>
        <span class="font-bold">{{ percentage }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Object,
      required: true
    }
  }
};
</script>
