<template>
  <div class="px-4 py-10">
    <div class="max-w-4xl mx-auto">
      <div class="relative border-l-2 border-gray-400">
        <div
            v-for="(event, index) in timelineEvents"
            :key="index"
            class="mb-10 ml-4"
        >
          <div
              class="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-white"
              :style="{ backgroundColor: event.color }"
          />
          <time class="mb-1 text-sm font-normal leading-none text-gray-400">{{ event.date }}</time>
          <h3 class="text-lg font-semibold text-gray-900">
            {{ event.title }}
          </h3>
          <p class="text-base font-normal text-gray-700">
            {{ event.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timelineEvents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for the VerticalOneSided layout */
</style>
