<template>
  <component
      :is="'pg-' + page"
      :id="id"
      @change-page="changePage"
  />

  <div v-if="showBasket">
    <FloatingBasket
        @change-page="changePage"
    />
  </div>
</template>

<script>
import FloatingBasket from "@/components/ecommerce/FloatingBasket.vue";

export default {
  name: "EcomMaster",
  components: {
    FloatingBasket
  },
  props: ["render", "config"],
  data() {
    return {
      page: '',
      id: null,
      history: [],
      showBasket: true
    }
  },
  mounted() {
    this.page = this.render.type
    this.id = this.render.id
  },
  methods: {
    changePage(page, id = null) {
      this.history.push({
        page: this.page,
        id: this.id
      })

      this.page = page
      this.id = id;
    }
  }
}
</script>

<style scoped>

</style>
