<template>
  <div class="p-4">
    <h2 class="font-semibold text-lg">
      {{ title }}
    </h2>
    <form @submit.prevent="submitVote">
      <div class="mt-4 space-y-2">
        <div
            v-for="option in options"
            :key="option.id"
        >
          <input
              :id="'option' + option.id"
              v-model="selectedOption"
              type="radio"
              name="poll"
              :value="option.id"
              class="w-4 h-4"
          >
          <label
              :for="'option' + option.id"
              class="ml-2 text-sm font-medium"
          >{{ option.label }}</label>
        </div>
      </div>
      <button
          type="submit"
          class="mt-4 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Vote
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedOption: ''
    };
  },
  methods: {
    submitVote() {
      this.$emit('vote-submitted', this.selectedOption);
    }
  }
};
</script>
