<template>
  <div class="fixed bottom-4 right-4 flex flex-col items-end">
    <div
        v-if="showChat"
        class="w-80 h-96 bg-white shadow-lg rounded-lg flex flex-col"
    >
      <div class="flex items-center p-4 bg-black rounded-t-lg">
        <img
            :src="profilePicture"
            alt="Customer Service"
            class="rounded-full"
            style="width: 2.5rem"
        >
        <h1 class="ml-4 font-semibold text-white">
          {{ serviceName }}
        </h1>
      </div>
      <div class="flex-1 p-4 overflow-auto">
        <div class="p-2 rounded-lg bg-blue-100 max-w-max mb-2">
          <p><strong>{{ serviceName }}</strong></p>
          <p>{{ initialMessage }}</p>
        </div>
      </div>
      <div class="px-4 pb-4 pt-2 border-t border-gray-200 flex">
        <div class="flex flex-wrap">
          <!-- Button 1 -->
          <button
              v-for="(source, index) in sources"
              :key="index"
              class="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full m-2"
              @click="sendMessage(source.link)"
          >
            <div
                v-html="source.icon"
            />
          </button>
        </div>
      </div>
    </div>
    <button
        style="margin-top: 10px;"
        class="whatsappButton bg-white hover:bg-gray-100 text-white font-bold py-2 px-4 rounded-full"
        @click="toggleChat"
    >
      Chat With Us
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'UniversalChatWidget',
  props: ['widgetId'],
  data() {
    return {
      showChat: false,
      userMessage: '',
      serviceName: 'Loading...',
      initialMessage: 'Please wait...',
      profilePicture: process.env.VUE_APP_APP_URL + '/imgs/blank-profile.png',
      sources: {}
    };
  },
  mounted() {
    this.fetchServiceInfo();
  },
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
    },
    sendMessage(baseRedirect) {
      window.open(baseRedirect, '_blank')
      this.userMessage = '';  // Clear the input after sending
    },
    async fetchServiceInfo() {
      // Api Connection
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.serviceName = apiResponse.display_name
      this.initialMessage = apiResponse.default_message;
      this.sources = apiResponse.sources;

      if (apiResponse.profilePicture) {
        this.profilePicture = apiResponse.profilePicture
      }
    }
  }
}
</script>

<style scoped>
/* Your styles here */
.whatsappButton {
  color: rgb(79, 206, 93);
  border-color: rgb(79, 206, 93);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px 0px;
}

.text-wp {
  color: rgb(79, 206, 93);
}
</style>
