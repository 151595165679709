<template>
  <div class="text-center">
    <div class="h-10 w-10 text-gray-500 mx-auto mb-2">
      <div v-html="icon" />
    </div>
    <h3 class="text-xl font-semibold">
      {{ title }}
    </h3>
    <p class="text-gray-600">
      {{ subtitle }}
    </p>
    <div class="mt-4 space-y-4">
      <FAQItem
          v-for="(faq, index) in faqs"
          :key="index"
          :question="faq.question"
          :answer="faq.answer"
      />
    </div>
  </div>
</template>

<script>
import FAQItem from './FAQItem.vue';

export default {
  name: "FAQCategory",
  components: {
    FAQItem,
  },
  props: {
    icon: String,
    title: String,
    subtitle: String,
    faqs: Array,
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>
