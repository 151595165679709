<!-- widget/QuizWidget.vue -->
<template>
  <div v-if="!loading">
    <transition
        name="fade"
        mode="out-in"
    >
      <component
          :is="currentQuestion.type === 'text' ? 'QuestionWithTextAnswers' : 'QuestionWithImageAnswers'"
          v-if="!quizCompleted"
          :key="currentQuestionIndex"
          :question="currentQuestion"
          :selected-answer-id="selectedAnswerId"
          :is-correct="isCorrect"
          @answerSelected="checkAnswer"
      />
    </transition>
    <transition
        name="fade"
        mode="out-in"
        appear
    >
      <QuizResult
          v-if="quizCompleted"
          :key="'result'"
          :quiz-completed-text="quizCompletedTextWithScore"
          @retryQuiz="resetQuiz"
      />
    </transition>
  </div>
</template>

<script>
import QuestionWithTextAnswers from './components/QuestionWithTextAnswers.vue';
import QuestionWithImageAnswers from './components/QuestionWithImageAnswers.vue';
import QuizResult from './components/QuizResult.vue';
import axios from "axios";

export default {
  name: 'QuizWidget',
  components: {
    QuestionWithTextAnswers,
    QuestionWithImageAnswers,
    QuizResult
  },
  props: ["widgetId"],
  data() {
    return {
      settings: {
          questions: [],
          quizCompletedText: 'Congratulations, You scored {{score}} on the quiz.',
          answers: []
        },
      currentQuestionIndex: 0,
      quizCompleted: false,
      userAnswers: [],
      selectedAnswerId: null,
      isCorrect: null,
      score: 0,
      loading: true
    };
  },
  computed: {
    currentQuestion() {
      return this.settings.questions[this.currentQuestionIndex];
    },
    quizCompletedTextWithScore() {
      return this.settings.quizCompletedText.replace('{{score}}', this.score + '/' + this.settings.questions.length);
    }
  },
  mounted() {
    this.fetchServiceInfo();
  },
  methods: {
    async fetchServiceInfo() {
      // Api Connection
      let rawApiResponse = await axios.get(
          process.env.VUE_APP_API_URL + "/api/widget/" + this.widgetId
      );
      let apiResponse = rawApiResponse.data;
      this.settings.questions = apiResponse.questions;
      this.settings.quizCompletedText = apiResponse.quiz_completed_text
      this.loading = !this.loading;
    },
    checkAnswer(answerId) {
      this.selectedAnswerId = answerId;
      this.isCorrect = this.currentQuestion.correctAnswers.includes(answerId);
      this.userAnswers.push({questionId: this.currentQuestionIndex, answerId, isCorrect: this.isCorrect});

      if (this.isCorrect) {
        this.score++;
      }

      setTimeout(() => {
        this.nextQuestion();
      }, 500);
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.settings.questions.length - 1) {
        this.currentQuestionIndex++;
        this.selectedAnswerId = null;
        this.isCorrect = null;
      } else {
        this.quizCompleted = true;
      }
    },
    resetQuiz() {
      this.quizCompleted = false;
      this.currentQuestionIndex = 0;
      this.userAnswers = [];
      this.selectedAnswerId = null;
      this.isCorrect = null;
      this.score = 0;
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
