<!-- widget/components/QuestionWithImageAnswers.vue -->
<template>
  <div class="max-w-lg mx-auto shadow-lg rounded-lg p-6">
    <h2 class="text-xl font-semibold mb-4">
      {{ question.question }}
    </h2>
    <div class="grid grid-cols-2 gap-4">
      <button
          v-for="option in question.options"
          :key="option.id"
          :class="{
          'bg-green-100': selectedAnswerId === option.id && isCorrect,
          'bg-red-100': selectedAnswerId === option.id && isCorrect === false,
          'hover:bg-gray-100': selectedAnswerId === null
        }"
          class="flex flex-col items-center p-2 border rounded-md"
          @click="selectAnswer(option.id)"
      >
        <img
            :src="option.image"
            alt="Option Image"
            class="mb-2"
        >
        <span>{{ option.text }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
    selectedAnswerId: Number,
    isCorrect: Boolean
  },
  methods: {
    selectAnswer(answerId) {
      if (this.selectedAnswerId === null) {
        this.$emit('answerSelected', answerId);
      }
    }
  }
};
</script>
