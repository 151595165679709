<template>
  <div class="flex flex-wrap gap-2">
    <div
        v-for="(review, index) in displayedReviews"
        :key="index"
        class="p-4"
        style="max-width: 400px"
    >
      <div
          class="border p-4 flex bg-gray-100"
          style="border-radius: 10px"
      >
        <div class="mr-4 flex-shrink-0">
          <img
              class="w-12 h-12 rounded-full"
              src="https://picsum.photos/48"
              alt="Avatar"
          >
        </div>
        <div>
          <div class="flex justify-between items-center">
            <h4
                class="font-semibold"
                style="max-width: 190px;"
            >
              {{ review.heading }}
            </h4>
            <span class="text-yellow-400 text-sm flex">
              <FontAwesomeIcon
                  v-for="(icon, i) in displayStarRating(review.rating)"
                  :key="i"
                  :icon="icon"
              />
            </span>
          </div>
          <p>
            <span v-if="!review.showFullReview">{{ getShortenedText(review) }}</span>
            <span v-else>{{ review.review }}</span>
          </p>
          <p class="text-blue-500 cursor-pointer">
            <span
                v-if="isReviewShortened(review)"
                @click="toggleReview(review)"
            >
              {{ review.showFullReview ? 'Read Less' : 'Read More' }}
            </span>
          </p>
          <p class="text-sm">
            {{ review.date }}
          </p>
          <p class="text-sm">
            {{ review.author }}, {{ review.source }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <button
      v-if="nextIndex < reviews.length"
      class="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
      @click="loadMore"
  >
    Load More
  </button>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faStar as fasStar, faStarHalfAlt as fasStarHalfAlt} from "@fortawesome/free-solid-svg-icons";
import {faStar as fasStarEmpty} from "@fortawesome/free-regular-svg-icons";

export default {
  components: {
    FontAwesomeIcon
  },
  props: ['reviews'],
  data() {
    return {
      displayedReviews: [],
      nextIndex: 0,
      maxLength: 100 // default max length of review preview
    };
  },
  watch: {
    reviews: {
      immediate: true,
      handler() {
        this.displayedReviews = []
        this.nextIndex = 0;
        this.loadMore();

      }
    }
  },
  mounted() {
    if (this.nextIndex === 0) {
      this.loadMore();
    }
  },
  methods: {
    displayStarRating(rating) {
      const fullStars = Math.floor(rating);
      const hasHalfStar = rating % 1 !== 0;
      const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
      let stars = [];
      for (let i = 0; i < fullStars; i++) {
        stars.push(fasStar);
      }
      if (hasHalfStar) {
        stars.push(fasStarHalfAlt);
      }
      for (let i = 0; i < emptyStars; i++) {
        stars.push(fasStarEmpty);
      }
      return stars;
    },
    loadMore() {
      const amount = 8; // number of reviews to load each time
      const nextReviews = this.reviews.slice(this.nextIndex, this.nextIndex + amount).map(review => ({
        ...review,
        showFullReview: false
      }));
      this.displayedReviews = this.displayedReviews.concat(nextReviews);
      this.nextIndex += amount;
    },
    isReviewShortened(review) {
      return review.review.length > this.maxLength;
    },
    getShortenedText(review) {
      return review.review.length > this.maxLength
          ? `${review.review.slice(0, this.maxLength)}...`
          : review.review;
    },
    toggleReview(review) {
      review.showFullReview = !review.showFullReview;
    }
  }
};
</script>
