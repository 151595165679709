<template>
  <div>
    <!-- Optionally display title and subtitle -->
    <div
v-if="title"
class="widget-title"
>
{{ title }}
</div>
    <div
v-if="subtitle"
class="widget-subtitle"
>
{{ subtitle }}
</div>

    <!-- Dynamic component based on API response -->
    <component
:is="currentTemplate"
:end-date="endDate"
:countdown-settings="settings"
/>
  </div>
</template>

<script>
import FlipClock from './templates/FlipClock.vue';
import axios from "axios";

export default {
  name: 'CountdownWidget',
  components: {
    FlipClock
  },
  props: ['widgetId'],
  data() {
    return {
      currentTemplate: null,
      endDate: null,
      showDays: false,
      title: '',
      subtitle: '',
      settings: {}
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let response = rawApiResponse.data;

      this.endDate = new Date(response.countdown_date);
      this.currentTemplate = response.template;
      this.showDays = response.showDays;
      this.title = response.title || ''; // Handle undefined or empty titles
      this.subtitle = response.subtitle || ''; // Handle undefined or empty subtitles

      this.settings.showDays = response.show_days;
      this.settings.showHours = response.show_hours;
      this.settings.showMinutes = response.show_minutes;
      this.settings.showSeconds = response.show_seconds;

    }
  }
}
</script>

<style scoped>
.widget-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.widget-subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
