<template>
  <div class="flex justify-center items-center">
    <div class="m-4 flex items-center">
      <!-- QR Code Section -->
      <div class="relative group">
        <div class="bg-blue-500 p-5 rounded-3xl shadow-md h-48 w-48">
          <img
              class="block  "
              :src="qrCodeUrl"
              alt="QR Code"
          >
        </div>
        <!-- Arrow Point -->
        <div class="absolute -right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 w-4 h-4 rotate-45" />
      </div>

      <!-- Text Section -->
      <div class="ml-8">
        <h2 class="text-xl font-bold text-blue-500">
          {{ title }}
        </h2>
        <p class="text-base text-gray-600">
          {{ subtext }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qrCodeUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to this layout */
</style>
