class LocalStorageService {
    setItem(key, value, ttlMinutes) {
        const expirationTime = Date.now() + (ttlMinutes * 60 * 1000); // TTL in milliseconds
        const data = { value, expirationTime };
        localStorage.setItem(key, JSON.stringify(data));
    }

    getItem(key) {
        const storedData = localStorage.getItem(key);
        if (!storedData) return null;

        const data = JSON.parse(storedData);
        const now = Date.now();

        if (now > data.expirationTime) {
            this.removeItem(key); // Remove expired item
            return null;
        }

        return data.value;
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }
}

export default new LocalStorageService();
