<template>
  <div class="max-w-4xl mx-auto p-8">
    <div class="space-y-4">
      <FAQItem
v-for="(faq, index) in faqs"
:key="index"
:question="faq.question"
:answer="faq.answer"
/>
    </div>
  </div>
</template>

<script>
import FAQItem from '../components/FAQItem.vue';

export default {
  components: {
    FAQItem,
  },
  props: {
    faqs: Array,
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>
