<!-- widget/components/QuestionWithTextAnswers.vue -->
<template>
  <div class="max-w-lg mx-auto shadow-lg rounded-lg p-6 space-y-4">
    <h2 class="text-xl font-semibold">
      {{ question.question }}
    </h2>
    <img
        v-if="question.image"
        :src="question.image"
        alt="Question Image"
        class="w-full h-auto rounded-md"
    >
    <div class="space-y-2">
      <button
          v-for="option in question.options"
          :key="option.id"
          :class="{
          'bg-green-100': selectedAnswerId === option.id && isCorrect,
          'bg-red-100': selectedAnswerId === option.id && isCorrect === false,
          'hover:bg-gray-100': selectedAnswerId === null
        }"
          class="w-full text-left px-4 py-2 border rounded-md"
          @click="selectAnswer(option.id)"
      >
        {{ option.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
    selectedAnswerId: Number,
    isCorrect: Boolean
  },
  methods: {
    selectAnswer(answerId) {
      if (this.selectedAnswerId === null) {
        this.$emit('answerSelected', answerId);
      }
    }
  }
};
</script>
