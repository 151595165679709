<template>
  <div>
    <component
        :is="layoutComponent"
        :qr-code-url="qrCodeUrl"
        :title="title"
        :subtext="subtext"
    />
  </div>
</template>

<script>
import HorizontalWithTitle from './layouts/HorizontalWithTitle.vue';
import axios from "axios";
// import other layouts as needed

export default {
  name: "QRCodeWidget",
  components: {
    HorizontalWithTitle,
  },
  props: ['widgetId'],
  data() {
    return {
      qrCodeUrl: '',
      title: '',
      subtext: '',
      layout: 'HorizontalWithTitle',
    };
  },
  computed: {
    layoutComponent() {
      return {
        'HorizontalWithTitle': HorizontalWithTitle,
        // map other layout components here
      }[this.layout];
    },
  },
  created() {
    this.fetchWidgetSettings();
  },
  methods: {
    async fetchWidgetSettings() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.qrCodeUrl = apiResponse.qrCodeUrl;
      this.title = apiResponse.title;
      this.subtext = apiResponse.subtext;
      this.layout = apiResponse.layout;
    },
  },
};
</script>
