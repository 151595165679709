import axios from 'axios';
import LocalStorageService from "@/services/LocalStorageService";

export default class ProductService {
    constructor(config) {
        this.config = config; // Base API URL
        this.baseUrl = 'https://' + config.site + '.' + process.env.VUE_APP_ECOM_BASE_URL

        // Create Axios instance with base URL
        this.axios = axios.create({
            baseURL: this.baseUrl,
        });
    }

    async getProduct(id) {
        let response = await this.axios('/api/v1/product/' + id);
        return response.data;
    }

    async addProductToBasket (id, variant) {
        let existingSessionId = LocalStorageService.getItem('cart-session');

        let type = 'product';
        let queryParams = '?session_id=' + existingSessionId

        if (variant) type = 'variant';

        let response = await this.axios.post('/api/cart/add/' + id + '/' + type + queryParams);
        return response.data
    }
}
