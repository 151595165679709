<template>
  <div>
    <VueMagnifier
        :src="image"
        width="500"
    />
  </div>
</template>

<script>
import VueMagnifier from '@websitebeaver/vue-magnifier'
import '@websitebeaver/vue-magnifier/styles.css'
import axios from "axios";
export default {
  name: "ImageMagnifierWidget",
  components: {
    VueMagnifier
  },
  props: ['widgetId'],
  data() {
    return {
      image: "",
    };
  },
  created() {
    this.fetchWidgetSettings();
  },
  methods: {
    async fetchWidgetSettings() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.image = 'https://projectinsanity.cloud' + apiResponse.image
    },
  },
};
</script>

<style scoped>
/* Add any general styles for the widget */
</style>
