<template>
  <div>
    <component
        :is="currentLayout"
        :timeline-events="timelineEvents"
    />
  </div>
</template>

<script>
import VerticalOneSided from './layouts/VerticalOneSided.vue';
import axios from "axios";

export default {
  name: "TimelineWidget",
  components: {
    VerticalOneSided,
  },
  props: ['widgetId'],
  data() {
    return {
      currentLayout: 'VerticalOneSided',
      'timelineEvents': [],
    };
  },
  methods: {
    async fetchWidgetSettings() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let apiResponse = rawApiResponse.data;

      this.timelineEvents = apiResponse.timelineEvents
    },
  },
  created() {
    this.fetchWidgetSettings();
  },
};
</script>

<style scoped>
/* Add any general styles for the widget */
</style>
