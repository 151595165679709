<template>
  <div v-if="loaded" class="container mx-auto p-4 pt-6 md:p-6 lg:p-12">
    <div class="flex flex-wrap -mx-4">
      <div class="md:w-1/2 xl:w-1/3 p-4">
        <img
            :src="tempProdImage"
            alt="Product Image"
            class="w-full h-full object-cover rounded-lg"
        >
      </div>
      <div class="md:w-1/2 xl:w-2/3 p-4 flex flex-col">
        <div>
          <h1 class="text-2xl font-bold mb-2">
            {{ product.name }}
          </h1>
          <p class="text-lg mb-4">
            £{{ getPrice }}
          </p>

          <div
              v-for="attribute in product.attributes"
              :key="attribute.name"
              class="mb-4"
          >
            <label class="block mb-2">{{ attribute.name }}:</label>
            <div class="flex">
              <button
                  v-for="option in attribute.options"
                  :key="option"
                  class="text-sm text-white py-1 px-3 mr-2 rounded-full"
                  :class="getButtonColor(attribute.name, option)"
                  @click="selectAttribute(attribute.name, option)"
              >
                {{ option }}
              </button>
            </div>
          </div>

          <div class="mb-4 flex items-center">
            <label class="block text-sm font-medium mr-4">Quantity:</label>
            <input
                v-model="quantity"
                type="number"
                min="1"
                class="w-16 text-center rounded-md border-gray-300 shadow-sm"
            >
          </div>

          <p class="text-sm text-gray-600 mb-4">
            In stock: {{ getStock }} available
          </p>
          <button @click="addToCart()" class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
            Add to Cart
          </button>
        </div>
        <div class="mt-4 flex-grow">
          <button
              class="text-left w-full text-white bg-gray-600 hover:bg-gray-800 font-medium py-2 px-4 rounded"
              @click="showDescription = !showDescription"
          >
            Description
          </button>
          <div
              v-if="showDescription"
              class="mt-2 text-gray-700"
          >
            <p>{{ product.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";
import {useBasketStore} from '@/store/basket';

export default {
  name: "ProductPage",
  props: ['id'],
  data() {
    return {
      loaded: false,
      showDescription: false,
      quantity: 1,
      selectedAttributes: {},
      tempProdImage: 'https://picsum.photos/400/500',
      variant: {},
      isVariant: false,
      product: {},
      productService: inject('productService')
    };
  },
  computed: {
    getPrice() {
      if (this.currentVariant && this.currentVariant.price) return this.currentVariant.price
      return this.product.price;
    },
    getStock() {
      if (this.currentVariant && this.currentVariant.current_stock_level) return this.currentVariant.current_stock_level
      return this.product.current_stock_level;
    }
  },
  async created() {
    await this.loadProduct()

    // Set default attributes
    for (const attribute of this.product.attributes) {
      this.selectedAttributes[attribute.name] = attribute.options[0];
    }

    this.getCurrentVariant();
  },
  methods: {
    async loadProduct() {
      this.product = await this.productService.getProduct(this.id)
      this.loaded = true;
    },
    getCurrentVariant() {
      const normalizedSelectedAttributes = Object.fromEntries(
          Object.entries(this.selectedAttributes).map(([name, value]) => [name, value])
      );

      // 1. Extract only the relevant attribute names (without 'attr-')
      const attributeNames = Object.keys(normalizedSelectedAttributes)
          .map(attr => attr.replace(/^attr-/, ''));

      // 2. Filter variants based on matching attributes
      let variant = this.product.variants.find(variant => {
        return attributeNames.every(name => {
          const variantValue = variant.attributes[name] || variant[`attr-${name}`];
          return variantValue === normalizedSelectedAttributes[name];
        });
      });

      if (variant) {
        this.isVariant = true;
        this.variant = variant;
      } else {
        this.isVariant = false;
        this.variant = this.product
      }

      return this.variant;
    },
    selectAttribute(name, option) {
      this.selectedAttributes[name] = option;
      this.currentVariant = this.getCurrentVariant();
    },
    getButtonColor(name, option) {
      const baseColor = {
        "Red": "bg-red-500",
        "Blue": "bg-blue-500",
        "Green": "bg-green-500",
      };
      const selectedColor = {
        "Red": "bg-red-700",
        "Blue": "bg-blue-700",
        "Green": "bg-green-700",
      };
      const defaultColor = "bg-gray-500";

      return option === this.selectedAttributes[name] ?
          (selectedColor[option] || "bg-gray-700") : // Selected color (or default if not in map)
          (baseColor[option] || defaultColor); // Default color (or default if not in map)
    },
    async addToCart() {
      let addToCart = await this.productService.addProductToBasket(this.currentVariant.id, this.isVariant)
      const basketStore = useBasketStore();
      basketStore.setItems(addToCart.items)
    }
  }
};
</script>
