<template>
  <div class="text-center py-4">
    <div v-if="activeTab === -1">
      <p class="text-2xl font-semibold">
        Overall Reviews
      </p>
      <ReviewStats :rating="overallRating" />
      <p class="text-lg">
        Based on {{ allReviews.length }} reviews
      </p>
      <ReviewList :reviews="allReviews" />
    </div>
    <div v-else>
      <p class="text-2xl font-semibold">
        {{ tabs[activeTab].name }} Reviews
      </p>
      <ReviewStats :rating="tabs[activeTab].starRating" />
      <p class="text-lg">
        Based on {{ tabs[activeTab].reviews.length }} reviews
      </p>
      <ReviewList :reviews="tabs[activeTab].reviews" />
    </div>
  </div>
</template>

<script>
import ReviewStats from './ReviewStats.vue';
import ReviewList from './ReviewList.vue';
export default {
  components: {
    ReviewStats,
    ReviewList
  },
  props: ['activeTab', 'tabs', 'allReviews', 'overallRating']
};
</script>
