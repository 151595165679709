<template>
  <div class="flex justify-between items-center p-4 shadow">
    <div class="flex items-center">
      <img
          :src="item.image"
          :alt="item.name"
          class="rounded mr-4"
          style="width: 50px; height: 50px;"
      >
      <div>
        <h3 class="font-bold">
          {{ item.name }}
        </h3>
        <p>{{ formatPrice(item.price) }}</p>
      </div>
    </div>
    <div class="flex items-center">
      <button
          class="px-2 py-1 border mr-2"
          @click="decrementQuantity"
      >
        -
      </button>
      <span>{{ item.quantity }}</span>
      <button
          class="px-2 py-1 border ml-2"
          @click="incrementQuantity"
      >
        +
      </button>
      <button
          class="text-red-500 ml-4"
          @click="emit('remove')"
      >
        Remove
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  emits: ['remove', 'update-quantity'],
  methods: {
    incrementQuantity() {
      this.$emit('update-quantity', this.item.quantity + 1);
    },
    decrementQuantity() {
      if (this.item.quantity > 1) {
        this.$emit('update-quantity', this.item.quantity - 1);
      }
    },
    formatPrice(price) {
      // Assuming price is in cents
      return `£${(price / 100).toFixed(2)}`;
    },
  },
};
</script>
